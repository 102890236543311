import React from "react";
import styled from "styled-components";
import ProcessCard from "../ProcessCard/ProcessCard";
import { highLightText } from "../globalFunc";

const SubHeading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #676767;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
  }
`;

const Heading = styled.div`
  .paragraph {
    overflow-wrap: break-word;
    width: 100%;
    margin: 0 auto;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: #3d3d3d;
    a {
    color: #1da1f2;
  }

  @media (max-width: 991px) {
    font-size: 31px;
  }
  @media (max-width: 767px) {
    width: 99%;
    font-size: 24px;
    line-height: 36px;
  }
}
 

`;

const CardWrapper = styled.div`
  padding-top: 70px;
  @media (max-width: 767px) {
    padding-top: 20px;
  }
  @media (max-width: 575px) {
    padding-top: 50px;
  }
`;
const CardDiv = styled.div`
  @media (max-width: 992px) {
    :last-child {
      margin-top: 60px !important;
    }
  }

  @media (max-width: 575px) {
    :not(:last-child) {
      margin-bottom: 60px;
    }
    :last-child {
      margin-top: 0 !important;
    }
  }
`;

const Section = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 100px;
  padding-right: 100px;

  @media (max-width: 575px) {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StagrowProcess = ({ data }: any) => {
  return (
    <Section className="container">
      <SubHeading className="text-center">{data.upperHeading}</SubHeading>
      <Heading className="text-center">
        {highLightText({
          text: data.heading,
          highlight: data.highlight,
          mainWrapper: "paragraph",
        })}
      </Heading>
      <CardWrapper className="row justify-content-evenly">
        {data.card.map((data: any, index: any) => (
          <CardDiv className=" col-md-6 col-lg-4" key={index}>
            <ProcessCard data={data} index={index + 1} />
          </CardDiv>
        ))}
      </CardWrapper>
    </Section>
  );
};

export default StagrowProcess;
