import React from "react";
import styled from "styled-components";
import PackageCard from "../PackageCard/PackageCard";

const Section = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 575px) {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .package{
    max-width: 1250px;
  }
`;
const CardWrapper = styled.div`
width: 100%;
 
  @media (max-width: 1025px) {
    justify-content: space-around !important;
  }
`;

const Packages = ({data}:any) => {
  return (
    <Section>
      <div className="container package">
        <CardWrapper className="row mx-auto">
          {data.map((item:any,index:number) => (
            <PackageCard data={item}  key={index}/>
          ))}
        </CardWrapper>
      </div>
    </Section>
  );
};

export default Packages;
