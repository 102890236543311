import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../utils/colors";
import { Like, Follower, View, InputArrow } from "../../assets/svg";
import { navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { UserContext } from "../Context";
import { toast } from "react-toastify";
import { highLightText } from "../globalFunc";

const Heading = styled.h1`
  font-weight: 700;
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 16px;
  color: #3d3d3d;
  @media (max-width: 767px) {
    font-size: 39px;
    text-align: center;
    padding-top: 31px;
  }
  @media (max-width: 575px) {
    font-weight: 600;
    font-size: 31px;
    padding-top: 15px;
    line-height: 36px;
  }
`;
const Insta = styled.span`
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
`;
const WrapperFun = styled.div`
  .paragraph {
    text-align: left;
    line-height: 2;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.01em;
    color: #676767;
    a {
      color: ${Colors.primaryColor};
    }
    @media (max-width: 991px) {
      text-align: center;
      font-size: 13px;
    }
    @media (max-width: 767px) {
      line-height: 21px;
      font-size: 14px;
      text-align: center;
    }
    @media (max-width: 575px) {
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const ButtonSection = styled.div`
  text-align: center;
  display: flex;
  height: 56px;
  border-bottom: 0.5px solid #e9e9e9;

  @media (max-width: 575px) {
    height: auto;
    padding-bottom: 20px;
    text-align: center;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 485px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 8px;
  padding: 0 3px 0 15px;
  margin-top: 32px;
  @media (max-width: 991px) {
    width: 100%;
  }
  input {
    width: 70%;
    outline: none;
    border: none;
    ::placeholder {
      opacity: 1;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: #a2a2a2;
    }
  }
`;
const BuyButton = styled.button`
  width: 111px;
  height: 48px;
  background: #1da1f2;
  border-radius: "8px";
  margin-right: 0;
  padding: 12px 40px;
  border-radius: 8px;
  text-decoration: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ffffff;
  border: none;
  :hover {
    opacity: 1;
    transition-duration: 0.6s;
    background: rgb(131, 91, 199);
  }
`;
const Box = styled.div`
  width: 549px;
  height: 200px;
  background: rgb(217, 217, 217, 0.2);
  border-radius: 8px;
  padding: 24px 32px;
  margin-top: 32px;
  @media (max-width: 575px) {
    height: auto;
  }
  @media (max-width: 767px) {
    margin: 32px auto;
  }
  @media (max-width: 991px) {
    width: 340px;
    padding: 24px 15px;
  }
`;
const Button = styled.button<{ isActive: boolean }>`
  width: auto;
  border: none;
  padding: 0 24px;
  height: 40px;
  background: ${(prop) => (prop.isActive ? "#1da1f2" : "transparent")};
  color: ${(prop) => (prop.isActive ? "#ffff" : "#676767")};
  border-radius: 41px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  margin-right: 5px;
  svg {
    margin-right: 6px;
    opacity: ${(prop) => (prop.isActive ? 1 : 0)};
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    padding: 0 12px;
  }
`;

const Section = styled.section`
  /* padding-top: calc(2rem + 247px); */
  margin-bottom: 28px;
  position: relative;
  background-color: #f9fdff;
  margin-bottom: 70px;
  @media (min-width: 1400px) and (max-width: 1919px) {
    margin-top: 70px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding-top: calc(2rem + 113px);
  padding-bottom: 70px;
  @media (max-width: 575px) {
    padding-top: calc(1rem + 120px);
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    padding-top: calc(1rem + 150px);
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    padding-top: calc(1rem + 190px);
  }
`;
const Right = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: end;

  @media (max-width: 767px) {
    height: 400px;
  }
`;

const HeroSection = ({ homeData }: any) => {
  const { userName, setUserName } = useContext(UserContext) as any;
  const [link, setLink] = useState("/steps?service=like");

  return (
    <>
      {/* <Section>
      <div className="container">
        <Container className="row">
          <div className="col-md-7 flex justify-content-evenly block">
            <div>
              <Heading>
                {homeData.heading.split(" ").map((a: string, index: number) =>
                  a === "Instagram" ? (
                    <Insta key={index} className="mx-1">
                      {a}
                    </Insta>
                  ) : (
                    `${a} `
                  )
                  )}
              </Heading>
              <WrapperFun>
                {highLightText({
                  text: homeData.subHeading,
                  highlight: homeData.heighLight,
                  mainWrapper: "paragraph",
                })}
              </WrapperFun>
            </div>
            <Box>
              <ButtonSection>
                <Button
                  onClick={() => setLink("/steps?service=like")}
                  isActive={link === "/steps?service=like"}
                  >
                  <Like />
                  Likes
                </Button>
                <Button
                  onClick={() => setLink("/steps?service=followers")}
                  isActive={link === "/steps?service=followers"}
                >
                  <Follower />
                  Follower
                </Button>
                <Button
                  onClick={() => setLink("/steps?service=views")}
                  isActive={link === "/steps?service=views"}
                  >
                  <View />
                  Views
                </Button>
              </ButtonSection>
              <InputWrapper>
                <InputArrow />
                <input
                  placeholder="Insta username"
                  onChange={(e) => setUserName(e.target.value)}
                  />
                <BuyButton
                  type="button"
                  onClick={() =>
                    userName === ""
                    ? toast.error("please enter username")
                    : navigate(link)
                  }
                  >
                  Buy
                </BuyButton>
              </InputWrapper>
            </Box>
          </div>
          <Right className="col-md-5 position-relative mt-4 mt-md-0">
            <HeroSectionImage></HeroSectionImage>
          </Right>
        </Container>
      </div>
      <div className="row">
        <div className="col-12 col-md-7"></div>
        <Right className="col col-md-5  mt-md-0 border-info block mt-4 p-0">
          <HeroSectionImage>
             <GatsbyImage
              alt={"hero image"}
              image={
                homeData.heroImage.localFile.childImageSharp.gatsbyImageData
              }
              /> 
          </HeroSectionImage> 
        </Right>
      </div>
    </Section> */}

      <Section>
        <div className="container">
          <Row className="row">
            <div className="col-md-7 flex justify-content-evenly block">
              <div>

                <Heading>
                  {homeData.heading.split(" ").map((a: string, index: number) =>
                    a === "Instagram" ? (
                      <Insta key={index} className="mx-1">
                        {a}
                      </Insta>
                    ) : (
                      `${a} `
                    )
                  )}
                </Heading>
                <WrapperFun>
      
                  {highLightText({
                    text: homeData.subHeading,
                    highlight: homeData.heighLight,
                    mainWrapper: "paragraph",
                  })}
                </WrapperFun>
              </div>
              <Box>
                <ButtonSection>
                  <Button
                    onClick={() => setLink("/steps?service=like")}
                    isActive={link === "/steps?service=like"}
                  >
                    <Like />
                    Likes
                  </Button>
                  <Button
                    onClick={() => setLink("/steps?service=followers")}
                    isActive={link === "/steps?service=followers"}
                  >
                    <Follower />
                    Follower
                  </Button>
                  <Button
                    onClick={() => setLink("/steps?service=views")}
                    isActive={link === "/steps?service=views"}
                  >
                    <View />
                    Views
                  </Button>
                </ButtonSection>
                <InputWrapper>
                  <InputArrow />
                  <input
                    placeholder="Insta username"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <BuyButton
                    type="button"
                    onClick={() =>
                      userName === ""
                        ? toast.error("please enter username")
                        : navigate(link)
                    }
                  >
                    Buy
                  </BuyButton>
                </InputWrapper>
              </Box>
            </div>
            <Right className="col-md-5 position-relative mt-4 mt-md-0">
              {/* <HeroSectionImage></HeroSectionImage> */}
            </Right>
          </Row>
        </div>

        <div className="row">
          <div className="col-12 col-md-7"></div>
          <Right className="col col-md-5  mt-md-0 border-info block mt-4 p-0">
            <GatsbyImage
              alt={"hero image"}
              image={
                homeData.heroImage.localFile.childImageSharp.gatsbyImageData
              }
            />
          </Right>
        </div>
      </Section>
    </>
  );
};

export default HeroSection;
