import React from "react";
import styled from "styled-components";
import ServiceCard from "../ServiceCard/ServiceCard";

export interface ServiceInfoIProps {
  data: any;
}

const Section = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  @media (max-width: 575px) {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const SubHeading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: #676767;
`;

const Heading = styled.div`
  width: 62%;
  margin: 0px auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 72px;
  text-align: center;
  color: #3d3d3d;

  @media (max-width: 991px) {
    width: 100%;
    font-size: 31px;
    line-height: 42px;
  }
  @media (max-width: 767px) {
    width: 100%;
    font-size: 27px;
    line-height: 35px;
  }
  @media (max-width: 575px) {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
  }
`;

const CardWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 70px;
  @media (max-width: 991px) {
    padding-top: 16px;
  }
  @media (max-width: 767px) {
    padding-top: 16px;
  }
  @media (max-width: 575px) {
    padding-top: 32px;
    padding-bottom: 10px;
  }
`;

const StagrowServices = ({ data }: ServiceInfoIProps) => {
  return (
    <Section className="px-2 px-sm-0 container">
      <SubHeading className="text-center">{data.upperTitle}</SubHeading>
      <Heading className="text-center">{data.heading}</Heading>
      <CardWrapper className="row justify-content-evenly ">
        {data.serviceCard.map((data: any, index: number) => (
          <div className="col-md-6 col-lg-4 mb-4 mb-lg-0" key={index}>
            <ServiceCard data={data} />
          </div>
        ))}
      </CardWrapper>
    </Section>
  );
};

export default StagrowServices;
