import React from "react";
import styled from "styled-components";
import BlogCard, { BlogDto } from "../BlogCard/BlogCard";

const Section = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 575px) {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .package {
    max-width: 1250px;
  }
`;
const CardWrapper = styled.div`
  width: 100%;

  @media (max-width: 1025px) {
    justify-content: space-around !important;
  }
`;

const Blogs = ({data}:{data:BlogDto[]}) => {
 

  // const data = [
  //   {
  //     heading: "10 Productivity Tips for Busy People",
  //     description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quasi exercitationem in explicabo recusandae inventore deserunt odit, officiis cumque minima quam quo aut tempora tempore consequatur ipsam eius laudantium sequi.`,
  //     image: "https://example.com/images/productivity-tips.jpg",
  //     author: "John Doe",
  //     slug: "10-productivity-tips-for-busy-people",
  //     authorImage: "https://example.com/images/john-doe.jpg",
  //     publishedDate: "2024-10-01",
  //     content:
  //       "# 10 Productivity Tips for Busy People\n\nBeing busy doesn't necessarily mean being productive. In fact, sometimes our busy schedules can make us feel overwhelmed and less efficient. Here are 10 productivity tips that will help you stay focused, organized, and get more done in less time.\n\n## 1. Start Your Day with a Plan\nIt all begins the night before. Before you go to bed, write down your top priorities for the next day. This simple step helps you start your day with a clear sense of purpose.\n\n## 2. Use Time Blocks\nInstead of working on tasks randomly, set aside specific blocks of time for different tasks. This method helps you focus and prevents distractions.\n\n## 3. Take Regular Breaks\nDon’t push yourself to work for hours straight. Studies show that taking short breaks every 25-30 minutes actually boosts productivity.\n\n## 4. Eliminate Distractions\nTurn off your phone notifications, close unnecessary browser tabs, and create a quiet, focused work environment.\n\n## 5. Prioritize Tasks\nFocus on high-priority tasks first. The rest can wait. Use a system like the Eisenhower Matrix to decide what's important and what's urgent.\n\n### Conclusion\nBy implementing these productivity tips into your daily routine, you’ll find yourself working smarter, not harder, and achieving your goals with less stress.",
  //   },
  //   {
  //     heading: "Tech Trends to Watch in 2024",
  //     description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quasi exercitationem in explicabo recusandae inventore deserunt odit, officiis cumque minima quam quo aut tempora tempore consequatur ipsam eius laudantium sequi.`,
  //     image: "https://example.com/images/tech-trends-2024.jpg",
  //     author: "Jane Smith",
  //     slug: "tech-trends-to-watch-in-2024",
  //     authorImage: "https://example.com/images/jane-smith.jpg",
  //     publishedDate: "2024-10-05",
  //     content:
  //       "# Tech Trends to Watch in 2024\n\nThe tech world is evolving faster than ever, and staying ahead of the trends is key to remaining competitive. Here are some of the top tech trends that are expected to shape the industry in 2024 and beyond.\n\n## 1. Artificial Intelligence (AI) and Machine Learning\nAI is expected to continue its rapid advancement in 2024, from improving customer service chatbots to automating complex business processes. Machine learning algorithms will become even more sophisticated and able to predict patterns with unprecedented accuracy.\n\n## 2. Quantum Computing\nQuantum computing is on the verge of breaking through in a way that could change everything. From solving previously impossible problems to advancing AI, 2024 could be the year that quantum computing takes its first real step into the mainstream.\n\n## 3. 5G and Beyond\nThe rollout of 5G networks will expand, enabling faster, more reliable internet connections that will transform industries ranging from healthcare to transportation. Expect to see new innovations made possible by this increased bandwidth.\n\n## 4. Virtual and Augmented Reality\nVirtual reality (VR) and augmented reality (AR) technologies will become more immersive and widely adopted. These technologies will revolutionize industries such as gaming, education, and healthcare.\n\n## 5. Cybersecurity Advancements\nAs cyber threats become more sophisticated, the demand for advanced cybersecurity solutions will grow. Expect AI-driven threat detection, biometric authentication, and blockchain-based security to be at the forefront of this industry.\n\n### Conclusion\nThe tech landscape is changing rapidly, and 2024 promises to be an exciting year. Keep an eye on these trends to stay ahead of the curve.",
  //   },
  //   {
  //     heading: "How to Stay Creative When You're Stuck",
  //     description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur quasi exercitationem in explicabo recusandae inventore deserunt odit, officiis cumque minima quam quo aut tempora tempore consequatur ipsam eius laudantium sequi.`,
  //     image: "https://example.com/images/creativity.jpg",
  //     author: "Alex Brown",
  //     slug: "how-to-stay-creative-when-youre-stuck",
  //     authorImage: "https://example.com/images/alex-brown.jpg",
  //     publishedDate: "2024-10-10",
  //     content:
  //       "# How to Stay Creative When You're Stuck\n\nCreativity can be elusive, especially when you need it most. Whether you're a writer, designer, or entrepreneur, there are times when inspiration simply isn't flowing. Here are a few strategies to help you get unstuck and reignite your creativity.\n\n## 1. Change Your Environment\nSometimes, a change of scenery is all it takes to spark creativity. Go for a walk, visit a new coffee shop, or simply rearrange your workspace.\n\n## 2. Collaborate with Others\nCollaboration can lead to breakthroughs that you might not have reached on your own. Talk to someone you trust, share your ideas, and see where the conversation takes you.\n\n## 3. Take a Break\nSometimes the best way to find your creative spark is to step away from the problem for a while. Engage in a completely different activity, like reading, cooking, or even exercising, to reset your mind.\n\n## 4. Keep a Creativity Journal\nKeep a notebook or digital document where you jot down any random ideas that come to mind, no matter how silly they seem. You never know when one of these ideas will turn into something brilliant.\n\n## 5. Embrace Imperfection\nPerfectionism can stifle creativity. Allow yourself to create without judgment, knowing that you can always refine and improve later.\n\n### Conclusion\nCreativity is not a constant flow, but a cycle of ups and downs. By trying out these strategies, you'll learn to navigate the slow moments and make space for your next big idea.",
  //   },
  // ];
  return (
    <Section>
      <div className="container package">
        <CardWrapper className="row mx-auto">
          {data.slice(0,3).map((item: BlogDto,index:number) => (
            <BlogCard  data={item} key={index}/>
          ))}
        </CardWrapper>
      </div>
    </Section>
  );
};

export default Blogs;
