import React from "react";
import styled, { css } from "styled-components";
import { ServiceCardIprops, styleProps } from "../../interface";
import { OrangeSvg } from "../../assets/svg";

const Heading = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  font-size: 18px;
  letter-spacing: 0.0125em;
  padding-top: 10px;

  @media (max-width: 1025px) {
    font-size: 15px;
    padding-top: 0px;
  }

  @media (max-width: 991px) {
    line-height: 15px;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    line-height: 18px;
    padding-top: 12px;
    padding-bottom: 0;
  }
`;

const Paragraph = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding-top: 18px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #676767;
  @media (max-width: 1200px) {
    padding-top: 5px;
    line-height: 12px;
  }
  @media (max-width: 1025px) {
    line-height: 18px;
    font-size: 12px;
  }
  @media (max-width: 991px) {
    padding: 2px 2px;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0px !important;
  }
  @media (max-width: 767px) {
    padding: 17px 0px;
    font-size: 13px;
    line-height: 32px;
  }
  @media (max-width: 575px) {
    padding: 6px 0px 0px 0px;
    font-size: 14px;
    line-height: 26px;
    margin-top: 10px;
  }
  @media (max-width: 575px) {
    line-height: 17px;
    padding: 0;
  }
`;

const CardWrapper = styled.div<styleProps>`
  ${(props) => {
    return css`
      background-color: ${props.color};
      border-radius: 23px;
      border: 2px solid ${props.borderColor};
      position: relative;
    `;
  }}
`;

const InnerCard = styled.div`
  padding: 0 21.93px;
  @media (max-width: 991px) {
    padding: 8.3px 3.93px 0px 25.93px;
  }
  @media (max-width: 575px) {
    padding: 4.3px 10.93px;
  }
`;
const ImageWrapper = styled.div`
  margin-left: -1px;
  height: inherit;
  width: 40%;
  position: relative;
  svg {
    height: 100% !important;
  }
  @media (max-width: 575px) {
    margin-right: 0px;
  }
`;

const ServiceCard = ({ data }: ServiceCardIprops) => {
  return (
    <CardWrapper borderColor={data.color} className="d-flex">
      <ImageWrapper>
        <OrangeSvg color={data.color} />
      </ImageWrapper>
      <InnerCard>
        <Heading>{data.title}</Heading>
        <Paragraph>{data.description}</Paragraph>
      </InnerCard>
    </CardWrapper>
  );
};

export default ServiceCard;
