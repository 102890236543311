import React from "react";
import styled from "styled-components";
import { ProcessCardIprop } from "../../interface";
import { One } from "../../assets/svg";

const Heading = styled.h4`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.0125em;
  font-size: 24.6203px;
  margin-bottom: 0;
  @media (max-width: 991px) {
    font-size: 17.6203px;
    line-height: 23px;
  }
`;

const Paragraph = styled.p`
  padding-top: 16px;
  font-family: "Poppins";
  font-size: 13.8489px;
  line-height: 21px;
  letter-spacing: 0.0125em;
  font-style: normal;
  font-weight: 400;
  @media (max-width: 991px) {
    font-size: 12.8489px;
    line-height: 20px;
  }
  @media (max-width: 767px) {
    font-size: 15.8489px;
    line-height: 40px;
  }
  @media (max-width: 575px) {
    line-height: 23px;
  }
`;

const CardWrapper = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 55.3956px;
  position: relative;
  height: 100%;
  @media (min-width: 1200px) {
    min-width: 340px;
  }
`;

const InnerCard = styled.div`
  padding: 35.3px 35.93px;
  height: 230px;
  color: ${(props) => props.color};

  @media (max-width: 575px) {
    /* padding: 55px 25px;
    height: 280px !important; */
  }
`;
const ImageWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: left;
  position: absolute;
  top: -25px;
  left: 10px;
  width: 200px;
  svg {
    path {
      fill: ${(prop) => prop.color};
    }
  }
`;
const Number = styled.div`
  position: relative;
  color: white;
  font-size: 28px;
  font-weight: 600;
  right: 37px;
`;
const ProcessCard = ({ data, index }: ProcessCardIprop) => {
  return (
    <CardWrapper color={data.bgColor}>
      <InnerCard color={data.color}>
        <Heading>{data.title}</Heading>
        <Paragraph>{data.description}</Paragraph>
        <ImageWrapper color={data.color}>
          <One />
          <Number>{index}</Number>
        </ImageWrapper>
      </InnerCard>
    </CardWrapper>
  );
};

export default ProcessCard;
