import { Link } from "gatsby";
import React from "react";

export const highLightText = ({ text, highlight, mainWrapper }: any) => {
  const line = text.split(" ");
  const words = highlight.map((e: any) => e.name);
  return (
    <div className={mainWrapper}>
      {line.map((a: any) =>
        words.includes(a)
          ? highlight.map((e: any, index: number) =>

           e.name === a ? (
                <Link
                  key={index}
                  to={e.link ? e.link : "/"}
                  className="text-decoration-none mx-1"
                >
                  {a}
                </Link>
              ) : null
            )
          : `${a} `
      )}
    </div>
  );
};
